body {
  margin: 0;
  padding: 0px 0px 0px 0px;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  overflow: auto;
}

#root {
  display: flex;
  flex-direction: column;
}

code {
  font-family: 'Lato', sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}